$md: 768px !default;
$lightTheme: #fafafa !default;

.cardElement {
  cursor: pointer;
  box-shadow: 0 1px 10px 3px #8c8c8c;
  border: none !important;
  height: 125px;
  width: 107px;
  margin-top: 2%;

  &:hover {
    @media (min-width: $md) and (orientation: landscape) {
      box-shadow: 1px 1px 2px 0px #999999;
      opacity: 1 !important;
    }
  }

  .card-img-top {
    margin-top: 15%;
  }

  .imgFrame {
    height: 120px;
    width: 100%;
    position: relative;
    border-radius: 0.2rem 0.2rem 0rem 0rem;
    background-color: #d9d9d9;

    // used in <ImgWithFallback />
    picture {
      justify-content: center;
      display: flex;
    }

    &.zoomed {
      overflow: hidden;
      justify-content: center;

      img {
        width: 200%;
        flex-shrink: 0;
      }
    }

    &.boltCard {
      height: 60%;
    }

    @media screen and (min-width: $md) {
      height: 162px;
    }
  }

  .checkMark {
    position: absolute;
    right: 2px;
  }

  &.disabled {
    opacity: 0.25;
  }

  .card-title {
    font-size: 1.2em;
  }

  .card-text {
    font-size: 0.8em;
  }
}
