.InputBox {
  .form-group {
    .label {
      color: #727272;
    }
    .form-control,
    .custom-select {
      background-color: white;
      border-radius: 8px;
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
    }
  }

  .input-container {
    position: relative;

    input {
      border: none;
      display: block;
      width: 100%;
    }

    img {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}
