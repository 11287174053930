$md: 769px !default;

.videoGuideModal {
  .iframe-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }

  .modal-content {
    padding: 8px;
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .modal-dialog {
    width: 90vw !important;
    max-width: 1000px;
    margin: auto;
  }
}

.Widgets {
  position: relative;
  z-index: 1;

  .float-left {
    position: absolute;
    left: 0;
  }

  .float-right {
    position: absolute;
    right: 0;
  }

  .toggleSizeButton {
    cursor: pointer;

    img {
      width: 24px;
    }
  }

  // customer support
  .supportCard {
    width: 335px;
    max-height: 75vh;
    position: fixed;
    top: 65px;
    left: 25px;
    background: white;
    border-radius: 8px;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
    z-index: 2;
    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (min-width: $md) {
      left: 370px;
      max-height: 100vh;
    }

    button {
      background-color: rgba(5, 79, 174, 0.7);
    }

    h4 {
      font-size: 22px;
    }

    p {
      font-size: 15px;
    }

    .topSection {
      height: 120px;
      color: white;
      border-radius: 8px 8px 0px 0px;
      background: rgb(93, 188, 210);
      background: linear-gradient(45deg, rgba(93, 188, 210, 1) 0%, rgba(5, 79, 174, 0.7) 100%);
    }

    .bottomSection {
      background: white;
      padding: 15px 0px;

      .form-group {
        button {
          display: -webkit-box;
        }

        .asterisk {
          color: #ff0000;
        }

        .form-control,
        .custom-select {
          background-color: white;
          border-radius: 8px;
          box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
        }
      }

      .submitNote {
        text-align: center;
        padding-bottom: 30px;

        img {
          width: 70%;
        }
      }
    }
  }
}
