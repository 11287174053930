$md: 768px !default;

.ControlsIndicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: 1;
  pointer-events: none;
  height: 90px;

  @media (min-width: $md) {
    height: 135px;
  }
}

// if you want to see the css animation code go to the skateboards.com project
