.ShoppingCart {
  h4 {
    color: #222;
  }

  h5 {
    font-weight: bold;
    color: #222;
  }

  button {
    background-color: #c71608;
    color: white;
    box-shadow: 0px 1px 2px #888888;
    border: none;
    border-radius: 4px;
    &:hover {
      background-color: #c71608;
    }
  }

  .checkoutBtn:disabled {
    background-color: #b5b4b4;
    &:hover {
      background-color: #b5b4b4;
    }
  }
}

.modal-dialog {
  .modal-header {
    background-color: #c71608;
    color: white;
  }

  input {
    height: 15px;
    width: 15px;
  }

  button {
    background-color: #c71608;
    color: white;
    box-shadow: 0px 1px 2px #888888;
    border: none;
    border-radius: 4px;
    &:hover {
      background-color: #c71608;
    }
  }

  .submitBtn:disabled {
    background-color: #b5b4b4;
    &:hover {
      background-color: #b5b4b4;
    }
  }
}
