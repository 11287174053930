.FileDragDrop {
  min-height: 250px;
  background-color: #e9edf0;
  border-radius: 4px;

  .errorMessage {
    color: red;
    font-style: italic;
  }

  .dropContainer {
    border: 1px dashed #c71608;
    border-radius: 4px;
    height: 100%;
  }

  .browseFilesButton {
    color: #0015d0;
    cursor: pointer;
  }

  img {
    width: 50%;
  }
}

.litPreset {
  &:hover {
    box-shadow: none !important;
  }
  &.activePreset {
    box-shadow: green 0px 0px 5px 2px !important;
  }
}
