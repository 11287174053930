@import "toastr";

$primary: #fa4516;
$body-color: #4f4f4f;

:root {
  --muted: #c9c9c9;
  --border-muted: #eee;
}

@import "node_modules/bootstrap/scss/bootstrap";

html {
  height: 100vh;
  height: -webkit-fill-available;
}
body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;
  cursor: auto !important;
  user-select: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  user-select: text;
}

.no-user-select {
  user-select: none !important;
}

// dat.gui debug
.dg.ac {
  z-index: 1;
}

#react-root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  #hud {
    height: 100%;
    width: 100%;
  }

  .price {
    color: green;
  }
}

.displayNone {
  display: none;
}

.brand-color {
  color: #c71608;
}

.hover-animation:hover {
  text-decoration: underline;
}

.backButton {
  width: 40px;
  cursor: pointer;
}

.toast-info {
  background-color: $primary !important;
}
.toast-warning {
  background-color: #ffc107 !important;
}

// #screenshot_canvas {
//   position: absolute;
//   z-index: 100000000000000000000;
//   width: 50vw;
//   bottom: 25px;
//   left: 0;
//   border: 5px solid black;
// }

// ***************
// fonts for MGP text engravings
// ***************

@font-face {
  font-family: "berlin_sans";
  src: url("/fonts/berlin_sans/BRLNSDB.woff2") format("woff2"), url("/fonts/berlin_sans/BRLNSDB.woff") format("woff"),
    url("/fonts/berlin_sans/BRLNSDB.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "brush_script";
  src: url("/fonts/brush_script/BrushScriptStd.woff2") format("woff2"), url("/fonts/brush_script/BrushScriptStd.woff") format("woff"),
    url("/fonts/brush_script/BrushScriptStd.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothic";
  src: url("/fonts/gothic/GOTHICB.woff2") format("woff2"), url("/fonts/gothic/GOTHICB.woff") format("woff"), url("/fonts/gothic/GOTHICB.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "impact";
  src: url("/fonts/impact/impact.woff") format("woff"), url("/fonts/impact/impact.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "script";
  src: url("/fonts/script/SCRIPTBL.woff2") format("woff2"), url("/fonts/script/SCRIPTBL.woff") format("woff"),
    url("/fonts/script/SCRIPTBL.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "stencil";
  src: url("/fonts/stencil/StencilStd.woff2") format("woff2"), url("/fonts/stencil/StencilStd.woff") format("woff"),
    url("/fonts/stencil/StencilStd.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
