$md: 769px !default;

.OverlayBadBrowsers {
  .OverlayBadBrowsersContainer {
    h6 {
      text-align: center;
    }

    img {
      width: 50%;
    }

    // mobile
    @media (max-width: $md) {
      width: 80%;
    }

    // desktop
    @media (min-width: $md) {
      width: 50%;
    }
  }
}
