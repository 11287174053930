.FontSection {
  .fontContainer {
    border-style: solid;
    border-width: 1px;
    padding: 3px;
    border-radius: 8px;
    cursor: pointer;

    &.active {
      border-color: #c71608;
      border-width: 2px;
    }

    &.disabled {
      opacity: 0.5;
    }

    .checkmark {
      margin-left: 5px;
    }
  }
}
