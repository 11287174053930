#BuildSection {
  position: fixed;
  z-index: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  left: 0;

  @media screen and (orientation: landscape) {
    top: 0;
    height: 100%;
    width: 385px;
  }

  @media screen and (orientation: portrait) {
    bottom: 0;
    height: 55%;
    width: 100%;
  }

  .headers {
    color: black;
  }

  .tastetest-brandmark {
    p {
      font-size: 13px;
    }
    img {
      width: 30px;
    }
  }

  .navigation {
    img {
      cursor: pointer;
      width: 45px;

      &.disable {
        opacity: 0.35;
        cursor: not-allowed;
      }
    }

    &.sticky {
      position: sticky;
      background-color: #fff;
      top: -1px;
      z-index: 3;
      margin-right: -12px; // break out of container padding
      margin-left: -12px;

      &.isSticking {
        box-shadow: 0px 3px 8px 1px #555;
      }
    }
  }
}

.arrow-button {
  position: relative;
  margin: 0;
  padding: 0px 5px;

  background: white;
  color: #111111;
  font-size: 12px;
  border: 1px solid #111111;

  color: #111111;
  &.disabled {
    border: 1px solid #111111;
  }
  &.active {
    background: #c71608;
    color: white;
    border-color: #c71608;
    &::after {
      background: #c71608;
      border-top-color: #c71608;
      border-right-color: #c71608;
    }
  }
  &:disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }
}

.next {
  margin-left: 15px;
  border-right: none;
  padding-right: 2px;
  &::after {
    content: "";
    display: block;
    position: absolute;
    right: -7px;
    border: 1px solid #111111;
    border-bottom: none;
    border-left: none;
    top: 2px;
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    z-index: -1;
    background: white;
  }
}

.prev {
  margin-right: 15px;
  border-left: none;
  padding-left: 2px;
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: -7px;
    border: 1px solid #111111;
    border-top: none;
    border-right: none;
    top: 2px;
    width: 14px;
    height: 14px;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    z-index: -1;
    background: white;
  }
}
