.ColorSection {
  .activeColorLabel {
    color: #111;
    text-shadow: 1px 1px 4px #999;
  }

  .colorItems {
    .color3D {
      cursor: pointer;
      width: 60%;
    }

    .colorDot {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;

      &.boxShadow {
        box-shadow: 0 0 50px #666666;
      }
    }

    .activeColor {
      border: 2px dotted grey;
      border-radius: 25%;
    }
  }

  // used for custom color section
  .alertText {
    color: #c71608;
    font-size: 13px;
    font-style: italic;
  }

  .form-group {
    .label {
      color: #727272;
    }
    .form-control,
    .custom-select {
      background-color: white;
      border-radius: 8px;
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
    }

    .questionBubble {
      margin: 0px 0px 10px 5px;
      cursor: pointer;
      height: 16px;
    }
  }

  .input-container {
    position: relative;

    input {
      border: none;
      display: block;
      width: 100%;
    }

    img {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}
.production-time {
  color: #c71608;
  font-size: 14px;
  text-align: center;
}
