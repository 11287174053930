.DecalsSection {
  .decalItems {
    background-color: black;
    max-height: 269px;
    overflow-y: auto;

    .decalCol {
      border-bottom: 1px solid grey;
      border-right: 1px solid grey;
    }

    .decalsContainer {
      cursor: pointer;
      height: 70px;
      position: relative;

      &.isActive {
        background-color: #ab0000;
      }
    }

    img {
      &.checkmark {
        position: absolute;
        height: 30px;
      }
    }

    p {
      color: white;
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: black;
    }
  }
}
