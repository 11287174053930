.errorScreen.sceneLoadingScreen {
  .contentContainer {
    max-width: 85%;

    h2 {
      text-align: center;
      color: white;
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }

    p {
      text-align: center;
      color: darkgrey;
      @media (max-width: 500px) {
        font-size: 12px;
      }
    }

    button {
      color: white;
      background-color: #c71608;
      width: 30%;
      min-width: 100px;
      padding: 10px;
      border-radius: 8px;

      &:hover {
        background-color: #dd291c;
      }
    }
  }
}
