.Stepper {
  // removes number in center of circle
  // .MuiStepIcon-text {
  //   display: none;
  // }

  .MuiStepLabel-label {
    user-select: none !important;
  }

  .MuiStepLabel-active {
    color: black !important;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 7px;
    color: #999;
    cursor: pointer;
  }
}
