.TabsContainer {
  background-color: #e9edf0;
  border-radius: 20px;
  max-width: 80%;
  height: 50px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);

  .tab {
    cursor: pointer;
    width: 75px;
  }

  .active {
    background-color: #c71608;
    border-radius: 12px;
    color: white;
  }
}
