.rectButton {
  z-index: 1;
  border-radius: 4px 4px 4px 4px;
  width: auto;
  border: none;
  user-select: none;

  // change this depending on client brand
  &.default {
    color: white;
    background-color: #c71608;
  }

  &.secondary {
    color: white;
    background-color: #777;
  }

  // change this depending on client brand
  &.outline {
    border-style: solid;
    border-color: #c71608;
    border-width: 1.5px;
    background-color: transparent;
    color: black;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.bottomNavigationBtn {
    height: 40px;
    padding: 0px 20px !important;
  }

  img {
    width: 30%;
    margin-left: 5px;
  }
}

#HUD {
  .rectButton {
    height: 38px;
    border-radius: 0px 0px 4px 0px !important;
  }
}
