$md: 769px !default;

.BuilderScreen {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: white;
  overflow: hidden;
}

.shared-scene-sizing {
  position: absolute;
  top: 0;

  height: 100% !important;
  width: calc(100vw - 385px) !important;
  margin-left: 385px !important;

  @media (orientation: portrait) {
    height: calc(100% - 55%) !important;
    width: 100% !important;
    margin-left: 0px !important;
  }

  // contains the 3D scene
  &.builder-scene-canvas-container {
    position: absolute !important;
    top: 0;
    cursor: grab;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;

    @media (orientation: portrait) {
      color: #5e5e5e;
      box-shadow: 0px 0px 10px 1px #5e5e5e;
    }
  }
}

.MuiLinearProgress-root {
  width: 100% !important;
  background: linear-gradient(90deg, rgba(223, 28, 32, 1) 0%, rgb(58, 56, 56) 53%, rgba(223, 28, 32, 1) 100%);
}

.MuiLinearProgress-barColorPrimary {
  background-color: #000000 !important;
}
