.svgPreview {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100px;
  max-height: 100px;
  z-index: 100;
}

#svgInject,
#imgInject,
.engravingCanvas {
  display: none;
  user-select: none;
  pointer-events: none;
}
