$md: 768px !default;
$lightTheme: #fafafa !default;

.cartCard {
  cursor: default;
  box-shadow: 0 1px 10px 3px #8c8c8c;
  border: none !important;
  margin-top: 2%;
  min-height: 197px;

  &.colorCard {
    .imgContainer {
      height: 86px;
    }
    img {
      width: 50%;
      object-fit: contain;
    }

    // used for custom color
    .colorDot {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;

      &.boxShadow {
        box-shadow: 0 0 50px #666666;
      }
    }
  }

  &.boltsCard {
    .imgContainer {
      height: inherit;
    }
    img {
      width: auto;
      max-height: 70px;
    }
  }

  &.engravingsCard {
    .imgContainer {
      height: 75px;
    }

    img {
      width: 95%;
      object-fit: contain;
    }
  }

  .redLinearGradient {
    height: 15px;
    background: linear-gradient(90deg, rgba(230, 36, 41, 1) 0%, rgba(0, 0, 0, 1) 100%);
    border-radius: 0.25rem 0.25rem 0 0;
  }

  .card-title {
    font-size: 14px;
    color: #777;
  }
  .card-text {
    font-weight: 600;
    font-size: 15px;
  }
}
