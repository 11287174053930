.togglesContainer {
  z-index: 2;
  background: #c71608;
  color: white;
  right: 0;
  top: 0;
  overflow: hidden;
  border-radius: 0px 0px 0px 4px;

  .pt1 {
    padding-top: 1px !important;
  }
}
